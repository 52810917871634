
import { Component, Watch } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import Dropdown from "@/components/Dropdown.vue";
import FormGroup from "@/components/FormGroup.vue";
import { CourseData, CourseDay, CourseDetail, CourseGroupInfo, CourseInfo, CourseTime } from "@/@types/course";
import FacilityService from "@/services/facilities";
import FacilityModule from "@/store/facility";
import _ from "lodash";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import { deleteNullProp } from "@/utils/Util";
import moment, { Moment } from "moment";
import Button from "@/components/Button.vue";
import Draggable from "vuedraggable";
import { computed } from "vue";

@Component({
  name: "CourseGroupSetting",
  components: {
    HospitalHeader,
    ManagementFooter,
    Dropdown,
    Button,
    FormGroup,
    Draggable,
  },
})
export default class CourseGroupSetting extends CheckCommonLogin {
  private saveSuccess = false;
  private courseGroups: CourseGroupInfo[] = [];
  private currentCourseGroup: CourseGroupInfo | null = null;
  private courses: CourseInfo[] = [];
  private isEditMode = false;
  private isCreateMode = false;
  private editIndex = 0;
  private selectedCourses = ["", "", ""];
  private isLoading = false;
  private precheckResult: any = null;
  private activeCourseLength = 0;
  private isEdited = false;
  private beforeEditCourseGroupName = "";
  private courseGroupName = "";
  @Watch("editForm", { deep: true })
  private toCourseSetting() {
    this.$router.push({ name: "CourseSetting" });
  }

  private get hospitalId() {
    return FacilityModule.hospitalId;
  }

  private get isDisabledAddCourseList() {
    if (this.isEditMode || this.isCreateMode) {
      return (
        this.activeCourseLength <= this.selectedCourses.length ||
        this.selectedCourses.filter((course: any) => course === "").length > 0
      );
    }
    return this.activeCourseLength <= this.selectedCourses.length;
  }

  private get selectCourseItems() {
    //courseGroupsのcourse_idsに含まれるコースは選択できないようにdisabled: trueにする
    const course_ids = this.courseGroups.reduce((acc: string[], courseGroup: CourseGroupInfo) => {
      acc.push(...courseGroup.course_ids);
      return acc;
    }, []);
    course_ids.push(...this.selectedCourses);
    return this.courses.map((course: CourseInfo) => {
      return {
        ...course,
        disabled:
          this.isEditMode && this.courseGroups[this.editIndex - 1].course_ids.includes(course.id!)
            ? this.selectedCourses.includes(course.id!)
            : course_ids.includes(course.id!),
      };
    });
  }

  private isSelectedCourse(course_id: string) {
    if (!course_id) return false;
    return this.selectedCourses.includes(course_id);
  }

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private courseEditMode(courseGroup: CourseGroupInfo, index: number) {
    courseGroup.course_ids.forEach((course_id: string, index: number) => {
      this.selectedCourses[index] = course_id;
    });
    this.beforeEditCourseGroupName = courseGroup.name;
    this.courseGroupName = courseGroup.name;
    // selectedCoursesが3個以上ある場合は、3の倍数になるまで空文字を追加する
    while (this.selectedCourses.length % 3 !== 0) {
      this.selectedCourses.push("");
    }
    this.isCreateMode = false;
    this.isEditMode = true;
    this.editIndex = index + 1;
    this.activeCourseLength =
      this.selectCourseItems.filter((course: any) => !course.disabled).length + courseGroup.course_ids.length;
  }
  private courseCreateMode() {
    this.isEditMode = false;
    this.isCreateMode = true;
    this.isEdited = false;
    this.selectedCourses = ["", "", ""];
    this.courseGroupName = "";
    this.beforeEditCourseGroupName = "";
    this.activeCourseLength = this.selectCourseItems.filter((course: any) => !course.disabled).length;
  }

  private async cancelMode() {
    await this.getCourseGroups();
    this.isEditMode = false;
    this.isCreateMode = false;
    this.isEdited = false;
    this.courseGroupName = "";
    this.beforeEditCourseGroupName = "";

    this.selectedCourses = ["", "", ""];
  }

  private closeSaveConfirm() {
    this.closeModal("saveConfirm");
    this.precheckResult = null;
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }
  private cancelSave() {
    this.closeModal("saveError");
  }
  private async getCourses() {
    try {
      const res = await FacilityService.getCourses(this.hospitalId);
      this.courses = res.data.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
  private async getCourseGroups() {
    try {
      const res = await FacilityService.getCourseGroups(this.hospitalId);
      this.courseGroups = res.data.data;
      this.activeCourseLength = 0;
      this.courseGroups.forEach((courseGroup: CourseGroupInfo) => {
        this.activeCourseLength += courseGroup.course_ids.length;
      });
      this.activeCourseLength = this.courses.length - this.activeCourseLength;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private async checkDuplicateCourseGroups() {
    await this.getCourseGroups();
    let course_ids = this.selectedCourses.filter((course_id: any) => course_id) as string[];
    if (this.isEditMode) {
      course_ids = this.selectedCourses.filter(
        (course_id: any) => course_id && !this.courseGroups[this.editIndex - 1].course_ids.includes(course_id)
      ) as string[];
    }
    const courseGroup = this.courseGroups.find((courseGroup: CourseGroupInfo) => {
      return courseGroup.course_ids.some((course_id: string) => course_ids.includes(course_id));
    });
    return courseGroup ? true : false;
  }

  private getCourseName(course_id: string) {
    const course = this.courses.find((course: CourseInfo) => course.id === course_id);
    return course?.name ?? "";
  }

  private async createCourseGroup() {
    const isChangeName = this.beforeEditCourseGroupName !== this.courseGroupName;

    if (!this.isEdited && !isChangeName) {
      this.cancelMode();
      return;
    }
    try {
      const precheckFlag = this.precheckResult ? true : false;
      const course_ids = this.selectedCourses.filter((course_id: any) => course_id) as string[];
      let precheckData: any = {};
      const duplicateFlag = await this.checkDuplicateCourseGroups();
      if (duplicateFlag) {
        this.showModal("duplicateError");
        this.isLoading = false;
        return;
      }
      if (!precheckFlag) {
        const precheckRes = await FacilityService.preCheckCourseGroup(this.hospitalId, course_ids);
        precheckData = precheckRes.data?.data[0].over_available_datetime ?? {};
      }
      if (Object.keys(precheckData).length === 0 || precheckFlag) {
        this.precheckResult = null;
        this.closeModal("saveConfirm");
        this.isLoading = true;
        const reqBody = isChangeName
          ? {
              course_ids: course_ids,
              name: this.courseGroupName,
            }
          : {
              course_ids: course_ids,
            };
        if (this.isEditMode) {
          await FacilityService.updateCourseGroup(this.hospitalId, this.courseGroups[this.editIndex - 1].id, reqBody);
        } else {
          await FacilityService.createCourseGroup(this.hospitalId, reqBody);
        }
        await this.getCourseGroups();
        this.isLoading = false;
        this.isCreateMode = false;
        this.isEditMode = false;
        this.isEdited = false;

        this.selectedCourses = ["", "", ""];
        this.courseGroupName = "";
        this.beforeEditCourseGroupName = "";
      } else {
        //precheckDataを日付順にソートする
        Object.entries(precheckData).forEach(([key, value]) => {
          const sortedValue = (value as string[]).sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);
            return dateA.getTime() - dateB.getTime();
          });
          precheckData[key] = sortedValue;
        });
        this.showModal("saveConfirm");
        this.precheckResult = Object.entries(precheckData).reduce((acc: any, [key, values]) => {
          acc[key] = (values as any).reduce((innerAcc: any, value: any) => {
            const date = new Date(value);
            const formattedDate = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
            const time = `${date.getHours()}:${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`;

            if (!innerAcc[formattedDate]) {
              innerAcc[formattedDate] = [];
            }

            innerAcc[formattedDate].push(time);

            return innerAcc;
          }, {});
          return acc;
        }, {});
      }
      this.closeModal("duplicateError");
      this.closeModal("saveError");
      this.getCourseGroups();
    } catch (error: any) {
      if (error.response && error.response.data?.error_msg.includes("already")) {
        // this.showModal("duplicateError");
        this.showModal("nameDuplicateError");
      } else {
        this.showModal("saveError");
      }
      this.isLoading = false;
      throw new Error(error);
    }
  }

  private async deleteCourseGroup() {
    try {
      this.isLoading = true;
      const course_group_id = this.courseGroups[this.editIndex - 1].id;
      await FacilityService.deleteCourseGroup(this.hospitalId, course_group_id);
      this.getCourseGroups();
      this.closeModal("delete");
      this.isEditMode = false;
      this.isLoading = false;
      this.isEdited = false;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private isSubmitDisabled() {
    const course_ids = this.selectedCourses.filter((course_id: any) => course_id) as string[];
    return course_ids.length < 2;
  }

  private get isAddCourseListDisabled() {
    const course_ids = this.selectedCourses.filter((course_id: any) => course_id) as string[];
    return course_ids.length !== this.selectedCourses.length;
  }

  private addCourseList() {
    this.selectedCourses.push(...["", "", ""]);
  }

  private async mounted() {
    await this.getCourses();
    this.getCourseGroups();
  }
}
